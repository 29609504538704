<app-banner [data]="{content: 'ASSOCIATION' | translate, title: 'ASSOCIATION' | translate}"></app-banner>

<div class="courses-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let item of list">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-1">
                                        <a class="d-block">
                                            <img class="association-img" [src]="item.picture? photoUrl+item.picture : 'assets/img/mosque-icon.svg'"
                                                alt="image">
                                        </a>
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <h3>{{item.title}}
                                        </h3>
                                        <p>{{item.description}}</p>
                                    </div>
                                    <div class="courses-box-footer" *ngIf="item.phone || item.email ||item.address">
                                        <div class="row info-area">
                                            <div class="info col-md mt-2" *ngIf="item.phone"> <i
                                                    class='bi bi-telephone-fill'></i> <a
                                                    href="tel:{{item.phone}}">{{item.phone}}</a></div>
                                            <div class="info col-md mt-2" *ngIf="item.email"> <i
                                                    class='bi bi-envelope-fill'></i> <a
                                                    href="mailto:{{item.email}}">{{item.email}}</a></div>
                                            <div class="info col-md-12 mt-2" *ngIf="item.address">
                                                <i class='bi bi-geo-alt-fill'></i>{{item.address}}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>