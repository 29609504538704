import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publish-card',
  templateUrl: './publish-card.component.html',
  styleUrls: ['./publish-card.component.scss']
})
export class PublishCardComponent {
  @Input() item: any;
  photoUrl: string = environment.serverUrl
}
