<div class="container events education" *ngIf="educationStatu=='Aktif' && education.length>0">
    <h4>{{'EDUCATION'|translate}}</h4>
    <div class="blog-slides">
        <owl-carousel-o [options]="carouselEventsSlide">

            <ng-template carouselSlide *ngFor="let item of education">
                <app-education-card [item]="item"></app-education-card>
            </ng-template>

        </owl-carousel-o>

        <a class="router-button" href="/egitim">
            <button class="view-all-contents-button ">

                {{'SEE_ALL' | translate}}

            </button>
        </a>
    </div>
</div>