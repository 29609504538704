
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-web-pages',
  templateUrl: './web-pages.component.html',
  styleUrls: ['./web-pages.component.scss'],

})
export class WebPagesComponent {
  photoUrl: string = environment.serverUrl
  content: any;
  lastData: any[] = [];
  public href = window.location.href;


  constructor(private activeRoute: ActivatedRoute, private sanitizer: DomSanitizer) { }


  ngOnInit() {
    this.activeRoute.data.subscribe((data: { data: any }) => {
      if (data.data) {
        this.content = data?.data?.data;
        const editorContainer = document.getElementById("dynamic-value");
        const shadow = editorContainer.attachShadow({ mode: "closed" });
        const content = document.createElement("div");
        content.innerHTML = this.content.content;
        shadow.appendChild(content);
      }
    });
  }
  


  missionSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  }
}
