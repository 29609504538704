<app-banner [data]="{content: 'NEWS' | translate, title: news.title , link: '/haberler'}"></app-banner>

<div class="blog-details-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc">
                    <div class="news-title">
                        <!-- <h4>{{news.title}}</h4> -->
                        <div class="new-date"></div>
                    </div>


                    <div class="news-video mb-3" *ngIf="news?.videoPath!=''">
                        <video controls [src]="photoUrl+news.videoPath" metadata>

                        </video>

                    </div>
                    &nbsp;
                    <ng-container *ngIf="news?.contentImages.length>0">
                        <swiper-container class="big-photo" appSwiper #swiper thumbs-swiper=".slider-preview"
                            autoplay="true" navigation="true" navigation="true">
                            <swiper-slide *ngFor="let item of news?.contentImages">
                                <div class="ratio ratio-4x3"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') no-repeat center center / contain'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                        <swiper-container class="slider-preview" appSwiper #swiperThumbs space-between="10"
                            slides-per-view="7" navigation="true" navigation="true"
                            *ngIf="news?.contentImages.length > 1">
                            <swiper-slide *ngFor="let item of news?.contentImages">
                                <div class="ratio ratio-4x3" role="button"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') center / cover'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </ng-container>

                    <div class="entry-meta mb-30 mt-30">
                        <ul>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>{{'NUMBER_OF_VIEWS'|translate}}</span>
                                <a>{{news?.viewCount? news?.viewCount :'NOT_VIEW_YET'|translate}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>{{'LAST_UPDATE'|translate}}</span>
                                <a>{{ news?.modifiedDate ? (news?.modifiedDate|date:'dd/MM/yyyy - hh:mm') :
                                    (news?.creationDate
                                    |date:'dd/MM/yyyy - hh:mm')}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="article-content">
                        <p id="dynamic-value"></p>
                        <a class="mt-30" [href]="photoUrl+news?.folderPath" *ngIf="news.folderPath" target="_blank">
                            <button class="cssbuttons-io-button">
                                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"
                                        fill="currentColor"></path>
                                </svg>
                                <span>{{'CLICK_DOWNLOAD'|translate}}</span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <div class="article-share ptb-70">

            <span>{{'SHARE'|translate}}</span>
            <ul class="social">
                <a [href]="['https://www.facebook.com/sharer/sharer.php?u='+href]" class="facebook" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <g clip-path="url(#clip0_1_688)">
                            <path
                                d="M11.5 0C5.14878 0 0 5.14878 0 11.5C0 16.893 3.71312 21.4185 8.72206 22.6614V15.0144H6.35076V11.5H8.72206V9.98568C8.72206 6.07154 10.4935 4.2573 14.3364 4.2573C15.065 4.2573 16.3222 4.40036 16.8365 4.54296V7.72846C16.5651 7.69994 16.0936 7.68568 15.508 7.68568C13.6224 7.68568 12.8938 8.40006 12.8938 10.2571V11.5H16.6502L16.0048 15.0144H12.8938V22.9158C18.5881 22.2281 23.0005 17.3797 23.0005 11.5C23 5.14878 17.8512 0 11.5 0Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_688">
                                <rect width="23" height="23" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>


                </a>
                <a [href]="['http://twitter.com/share?url='+href+'&text=Bu%20g%C3%B6nderiyi%20g%C3%B6r']"
                    routerLinkActive="router-link-active" class="twitter" target="_blank">

                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                            d="M13.7447 1.42773H16.2748L10.7473 7.74535L17.25 16.3422H12.1584L8.17053 11.1283L3.60746 16.3422H1.07582L6.98808 9.58481L0.75 1.42773H5.97083L9.57555 6.19348L13.7447 1.42773ZM12.8567 14.8278H14.2587L5.20905 2.86258H3.7046L12.8567 14.8278Z"
                            fill="white" />
                    </svg>


                </a>



            </ul>
        </div>

        <div class="courses-area pb-70" *ngIf="lastData.length>0">
            <div class="container">
                <h4>{{'LAST_POSTS'|translate}}</h4>
                <div class="row">
                    <div class="col-lg-3 col-md-6" *ngFor="let item of lastData">
                        <div class="last-news-box mb-30">
                            <div class="courses-image">

                                <a style="cursor: pointer;" (click)="navigate(item.id)" class="d-block">
                                    <img
                                        [src]=" item.thumbnail ? photoUrl+item?.thumbnail : ['assets/img/DitibLogoBuyuk.png']"></a>

                            </div>
                            <div class="courses-tag row">
                                <div class="line-clamp-three">
                                    <a style="cursor: pointer;" (click)="navigate(item.id)">
                                        <p>{{item.title | lowercase}}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>