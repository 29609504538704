<div class="container news-area" *ngIf="news.length>0">
    <div class="blog-slides">
        <div class="animation-transition">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide *ngFor="let item of news">
                    <app-news-card [item]="item"></app-news-card>
                </ng-template>
            </owl-carousel-o>
        </div>
        <a class="router-button" routerLink="/haberler">
            <button class="view-all-contents-button blue">
                {{'SEE_ALL' | translate}}
            </button>
        </a>
    </div>
</div>