import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService } from 'src/app/services/appsetting.service';

@Component({
  selector: 'app-left-box',
  templateUrl: './left-box.component.html',
  styleUrls: ['./left-box.component.scss']
})
export class LeftBoxComponent {
  link: string = ''
  constructor(private appSettingService: AppSettingService, private router: Router) { }

  ngOnInit() {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.link = data.find(item => item.key === "genelbagislink")?.value;
    })
  }



}
