import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContentService } from '../../../services/content.service';

@Component({
  selector: 'app-salmon-page',
  templateUrl: './salmon-page.component.html',
  styleUrls: ['./salmon-page.component.scss']
})
export class SalmonPageComponent {
  classApplied2 = false;
  yearArray: number[] = [];
  monthArray: any[] = [];
  selectedYear: number;
  selectedMonth: string;
  creationDate = '1.01.0001%2000:00:00';
  skip: number = 0;
  pageSize: number = 12;
  totalCount: number = 0;
  list: any[];
  photoUrl = environment.serverUrl



  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.getAll();
    this.getYearandMonth();

  }

  getAll() {
    this.contentService.getAllContentResource(8, '', this.creationDate, this.skip, this.pageSize).subscribe((data) => {
      this.list = data.data.sort((a,b)=>b.order-a.order);
      this.totalCount = data.totalCount

    })


  }

  getYearandMonth() {
    const year = Number(new Date().getFullYear());
    this.selectedYear = year

    for (let index = year; index <= year; index++) {
      this.yearArray.push(index);
    }

    for (let i = 0; i <= 11; i++) {
      const monthName = new Date(2000, i).toLocaleString('default', { month: 'long' });
      this.monthArray.push(
        {
          id: i + 1,
          name: monthName
        }
      );
    }

    this.selectedMonth = new Date().toLocaleString('default', { month: 'long' });
  }



  filter(id) {
    this.creationDate = `01.${id}.${this.selectedYear}`;
    this.creationDate = `${this.selectedYear}.${id}.01`;
    this.getAll();
  }

  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }


  onPageChange(e) {
    this.skip = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAll();

  }

}
