
<div class="example-1 card">
    <div class="wrapper" [ngStyle]="{ 'background-image': item?.thumbnail ? 'url(' + photoUrl + item?.thumbnail + ')' : 'url(/assets/img/DitibLogoBuyuk.png)' }">
        <div class="date">

            <span class="year">{{item?.creationDate | date: 'dd.MM.yyyy'}}</span>
        </div>
        <div class="data">
            <div class="content text-center">
                <p class="title line-clamp-three"><a [href]="['haber-detay/'+item.id]">{{ item?.title }}</a></p>
            </div>
        </div>
    </div>
</div>