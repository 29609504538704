<app-banner [data]="detail.contentTypeId== 28 ? {content: 'MEMORIES' | translate, title: detail.title , link:'/anilar'} : {content: 'ANNOUNCEMENT_LITTLE' | translate, title: detail.title}"></app-banner>


<div class="blog-details-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc">
                    <div class="news-title">
                        <!-- <h4>{{detail.title}}</h4> -->
                    </div>
                    &nbsp;
                    <ng-container *ngIf="detail?.contentImages.length>0">
                        <swiper-container class="big-photo"
                            appSwiper #swiper thumbs-swiper=".slider-preview"navigation="true" autoplay="true" navigation="true">
                            <swiper-slide *ngFor="let item of detail?.contentImages">
                                <div class="ratio ratio-4x3"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') no-repeat center center / contain'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                        <swiper-container class="slider-preview"
                            appSwiper #swiperThumbs  space-between="10" slides-per-view="7" navigation="true"
                            *ngIf="detail?.contentImages.length > 1" navigation="true">
                            <swiper-slide *ngFor="let item of detail?.contentImages">
                                <div class="ratio ratio-4x3" role="button"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') center / cover'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </ng-container>
                    <div class="entry-meta mb-30 mt-30">
                        <ul>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>{{'NUMBER_OF_VIEWS'|translate}}</span>
                                <a>{{detail?.viewCount? detail?.viewCount :'NOT_VIEW_YET'|translate}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>{{'LAST_UPDATE'|translate}}</span>
                                <a>{{ detail?.modifiedDate ? (detail?.modifiedDate|date:'dd/MM/yyyy - hh:mm') :
                                    (detail?.creationDate
                                    |date:'dd/MM/yyyy - hh:mm')}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="container">
                        <div class="article-content">
                            <p id="dynamic-value"></p>
                        </div>
                    </div>
                    <div class="article-content">
                        <p id="dynamic-value"></p>
                        <a class="mt-30" [href]="photoUrl+detail?.filePath" *ngIf="detail.filePath" target="_blank">
                            <button class="cssbuttons-io-button">
                                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"
                                        fill="currentColor"></path>
                                </svg>
                                <span>{{'CLICK_DOWNLOAD'|translate}}</span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>