<div class="container events " *ngIf="eventStatu=='Aktif' && event.length>0">
    <h4 style="color:var(--mainColor)">{{'EVENTS'|translate}}</h4>
    <div class="blog-slides ">
        <owl-carousel-o [options]="carouselEventsSlide">
            <ng-template carouselSlide *ngFor="let item of event">

                <app-event-card [item]="item"></app-event-card>

            </ng-template>

        </owl-carousel-o>

        <a class="router-button" href="/etkinlikler">
            <button class="view-all-contents-button blue">
                {{'SEE_ALL' | translate}}
            </button>
        </a>
    </div>
</div>